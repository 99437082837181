// // TODO:
// Reduce size of files by using separate avg file, and then maybe field translations?

// REFACTOr
// dont' need to resassign values in update color always. color for ex. can be done sooner
// turn off voronoi completely?

(function(){


var showVoronoi = false

const d3 = require('d3')

// Broadest functions
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Viz selector
const selector = '.viz-target'
var width, height, margin, selectorWidth, selectorHeight
function getVizProps(){
  d3.select(selector).each(function(){
    selectorWidth = this.getBoundingClientRect().width
    selectorHeight = window.innerHeight-50-$('.header').outerHeight()-$('.sub-header').outerHeight()
  })

  // Overall vars
  margin = {
    left: 80,
    right: 50,
    top: 30,
    bottom: 80
  }
  if(window.innerWidth < 600){
    margin = {
      left: 60,
      right: 40,
      top: 30,
      bottom: 40
    }
  }
  width = selectorWidth - margin.left - margin.right
  var sourceHeight = 30
  height = window.innerWidth < 600 ? window.innerHeight*.5 -margin.top - margin.bottom - sourceHeight : selectorHeight - margin.top - margin.bottom - sourceHeight
}

getVizProps()

const circleSize = 5
const axisColor = '#EBEBEA';

const orientValues = {
  top: {dy: -10, dx: 0, textAnchor: 'middle'},
  right: {dy:5, dx: 8, textAnchor: 'start'},
  bottom: {dy: 18, dx: 0, textAnchor: 'middle'},
  left: {dy:5,dx: -8, textAnchor: 'end'}
}
const averageOrient = ({
  top: {dy: -13, dx: 0, textAnchor: 'middle'},
  right: {dy:5, dx: 12, textAnchor: 'start'},
  bottom: {dy: 16, dx: 0, textAnchor: 'middle'},
  left: {dy:5,dx: -12, textAnchor: 'end'},
  'top-right': {dy:-5, dx: 12, textAnchor: 'start'},
  'bottom-left': {dy: 16, dx: -10, textAnchor: 'middle'},
})
const textDisplay = ({
  show: text => text.style('opacity', 1),
  hide: text => text.style('opacity', 0)
})
// Colors
const mainColor = '#29B6A3';
const lighterColor = '#4DC1B4';
const negativeColor = '#E64060';
const colorArr = [
'#7F4893', //1: "Education"
'#20908E', //3: "In Kind Transfers"
'#6BBE46', //2: "Social Insurance"
'#E64060', //0: "Taxes"
'#FFD400',  //4: Spare
'#7F4893'
]

// Default chart options
var showingPrograms = true
var showingLabels = false
var showingAverages = false
var showingCI = false
var showingProgramCI = false
var animate = false
var forced = true
var redoAxesBy = 'auto'
var defaultAnimationTime = 1000
var lastSlide = null
var showingControls = false
var tempKillScroll = false
var killTooltipHover = false
var alwaysShowColor = false

// Create SVGs
const svgW = d3.select(selector)
    .append('svg')
  .attr('width', width + margin.left + margin.right)
  .attr('height', height + margin.top+margin.bottom)

const svg = svgW.append('g')
  .attr('transform',`translate(${margin.left},${margin.top})`)
  .attr('class', 'svg-g')
// Tooltip
const tooltip = d3.select('.tooltip')

// Overall functions
var parseNumber = (n) => parseFloat(n.replace(/\$\,/g,''))
d3.selection.prototype.moveToFront = function() {
  return this.each(function(){
    this.parentNode.appendChild(this)
  })
}
d3.selection.prototype.dedupeLabels = function() {
  if(showingLabels){
    // allDedupeLabels should contain all the objects you want to consider for de-duping
    // Use class "dedupe" when generating each object. Then add "dedupe-always-show" to things you want to show regardless (like circles)

    var hideables = []

    // Cycle through dedupables and dedupe them
    var allDedupeLabels = this.filter(function(d){ return d.thisIn })
    allDedupeLabels.each(function(d, i) {

      // Get bounding box
      var thisBBox = this.getBBox()

      // Iterate through each box to see if it overlaps with any following
      // If they do, hide them
      // Get labels after this one
      allDedupeLabels.filter((k, j) => j > i).each(function(d){
          var underBBox = this.getBBox()
          // If not overlapping with a subsequent item, and isn't meant to be shown always (like circles), hide it
          if(hideables.indexOf(d3.select(this).datum()['id']) == -1 && getOverlapFromTwoExtents(thisBBox, underBBox) && d3.select(this).attr('class').match('dedupe-always-show') == null){
            hideables.push(d3.select(this).datum()['id'])
          }
      })
    })

    svg.selectAll('text.dedupe')
      .attr('class', function(d){
        if(!d.thisIn || hideables.indexOf(d.id) > -1){
          // Should hide
          return d3.select(this).attr('class') + ' label-hide'
        } else {
          // Should show
          return d3.select(this).attr('class').replace('label-hide', 'label-show')
        }
      })
  }
}
var getOverlapFromTwoExtents = (l, r) => {
  var overlapPadding = 10
  l.left = l.x - overlapPadding
    l.right = l.x + l.width + overlapPadding
    l.top = l.y - overlapPadding
    l.bottom = l.y + l.height + overlapPadding
  r.left = r.x - overlapPadding
    r.right = r.x + r.width + overlapPadding
    r.top = r.y - overlapPadding
    r.bottom = r.y + r.height + overlapPadding
  var a = l
  var b = r
  if (a.left >= b.right || a.top >= b.bottom ||
      a.right <= b.left || a.bottom <= b.top ){
    return false
  } else {
    return true
  }
}

var getCircleSize = (d) => {
  return d.group_average == 0 ? circleSize : circleSize * 1.5
}

data = data.map((d,i) => {d.id = 'id-'+i; return d})

// Process average data to append ID
var processedAvg = data.filter(d => d.group_average == 1)
// Trigger build using processed data
var processedData = data.filter(d => d.group_average == 0).map((d,i) => {
  for(var i in d){
    var k = d[i]
    if(i.toLowerCase().match('year') == null && !isNaN(parseInt(k))) d[i] = parseFloat(parseFloat(d[i]).toFixed(2))
  }
  return d
})

// Run simulation
build(processedData, processedAvg)

  // Viz creation function
  function build(data, averageData){

    // Data vars
    const programColumn = 'program'
    const groupColumn = 'group'
    const labelColumn = 'small_label_name'
    const longLabelColumn = 'long_description'
    const parentCategoryColumn = 'group' // Parent category
    const categoryColumn = 'prog_type' // Program category
    const baselineColumn = 'baseline'
    const restrictedColumn = 'restricted'
    const extendedColumn = 'extended'
    const idColumn = 'id'
    const mvpfColumn = 'mvpf'
    const ageColumn = 'age_benef'
    const yearColumn = 'year_implementation'
    const costColumn = 'c_on_pc'
    const willingnessColumn = 'w_on_pc'
    const citationsColumn = 'paper_cite_keys'
    const avgLabel = '_avg'
    const upperCI = '_u'
    const lowerCI = '_l'
    // Assumptions
    const assumptionRobustness = '_rob'
    const assumptionLowerBound = '_lbwtp'
    const assumptionPublicationBias = '_pbc'
    var assumptionSel = ''
    // Samples
    const sampleBaseline = ''
    const sampleRestricted = '_res'
    const sampleExtended = '_ext'
    var sampleSel = ''
    const assumptionPublicationBiasRobustness = assumptionPublicationBias+assumptionRobustness
    const assumptions = [assumptionRobustness, assumptionLowerBound, assumptionPublicationBias, sampleRestricted, sampleExtended]

    var ySelExt = ''
    // Arrays from data
    let categories = d3.nest().key(d => d[categoryColumn]).sortValues((a,b) => a-b).entries(data).map(d => d.key)
    const parentCategories = d3.nest().key(d => d[parentCategoryColumn]).entries(data).map(d => d.key).filter(d => d !== '').sort()
    const programNames = d3.nest().key(d => d[programColumn]).entries(data).map(d => d.key)
    const programShortNames = d3.nest().key(d => d[labelColumn]).entries(data).map(d => d.key)
    const programLongNames = d3.nest().key(d => d[longLabelColumn]).entries(data).map(d => d.key)
    const programLabelsHashed = d3.map(data, d => d[longLabelColumn])
    const upfrontHighlightsKeys = Object.keys(upfrontHighlights)
    const upfrontHighlightsHashed = d3.map(upfrontHighlights, d => d)
    const labelNames = d3.map(labelMap, d => d.variable)
    // Selections
    var categorySelections = categories;
    var programHighlight = null;
    const ySelections = [
      mvpfColumn,
      costColumn,
      willingnessColumn
    ]
    const xSelections = [
      ageColumn,
      yearColumn
    ]
    const tableValues = [
      programColumn,
      costColumn,
      willingnessColumn,
      mvpfColumn,
      citationsColumn
    ]



    // Add matching value for averages on each program
    processedData.map((d,i) => {
      processedAvg.forEach(k => {
        // Match average "parent category" name to program's "parent category" name
        if(k[categoryColumn] == d[categoryColumn]){
          match = k
        }
      })
      d.match = match
    })


    // Category and colors
    var colorScale = d3.scaleOrdinal().domain(parentCategories).range(colorArr)
    // Selection vars
    var ySel = mvpfColumn
    var averageYSel = null
    var xSel = yearColumn
    // Slides: Vars to show/hide specific things per slide
    var applyCheckTo = null // options: programs, averages, confidence intervals
    var check = null // What kind of check to do
    var checkColumn = xSel // What column to check against
    var checkValue = 0 // What value to check against
    var colorBy = parentCategoryColumn // How to color the programs

    // Make HTML from data
    // Make clickable program list
    categories = categories.sort()
    d3.select('#dropdown-domains').selectAll('div.category-item')
      .data(categories).enter()
    .append('div')
      .attr('class', d => {
        var m = data.filter(k => k[categoryColumn] == d)[0]
        return 'checkbox category-item selected ' + m[groupColumn].replace(/ /g, '-')
      })
      .attr('data-domain', d => d)
      .html(d => d + ' <a href="#only" class="only">Only</a>')
    // Make dropdown selector
    d3.select('#dropdown-programs').selectAll('option')
      .data(programLongNames).enter()
    .append('option')
      .text(d => d)

    // Dropdown
    // Change location
    $('#dropdown-programs').select2({
      placeholder:'Highlight a program',
      allowClear: true
    }).on('change', function(e){
      // Show only this one
      var val = programLabelsHashed.get($(this).val())
      if(val){
        programHighlight = val ? [val[longLabelColumn]] : []
        updateViz()
        dataGroup.each(d => {
          if(d[longLabelColumn] == programHighlight[0]){
            showTooltip(d, true)
          }
        })
      }
    })

    // Control chart controls
    $(window).resize(function(){
      resizeViz()
      updateViz()
      $('.controls-flex').css({
        height:window.innerHeight - $('.header').outerHeight() - $('.sub-header').outerHeight() - 30
      })
    })
    $('body,html').on('mousedown', function(){
      // Allow tooltips again
      setTimeout(function(){
        killTooltipHover = false
      },10)
      $('#dropdown-programs').select2('val', 'Highlight a program')
      // $('.tooltip').css('opacity', '0')
    })
    $('.lower').click(function(e){
      if (e.target !== this) return
      $('.lower').fadeOut()
      $('body').removeClass('hidden')
      return false;
    })
    $('.hover-category').hover(function(){
      circles.transition().duration(defaultAnimationTime)
        .attr('fill', d => {
          return d[groupColumn] == $(this).data('group') ? colorScale(d[parentCategoryColumn]) : '#eee'
        })
      dataGroup.filter(d => d[groupColumn] == $(this).data('group'))
        .moveToFront()

      voronoiLabels.moveToFront()

    }, function(){
      circles.transition().duration(defaultAnimationTime)
        .attr('fill', d => {
          return colorScale(d[parentCategoryColumn])
        })
    })
    $('.show-hide-methodology').click(function(){
      $('.lower').fadeIn()
      $('#methodology-body').scrollTop(0)
      $('body').addClass('hidden')
      premakeBarChart()
      return false;
    })
    $('.reset-button a').click(function(){
      updateSlide('outro')
      $('.controls .radio, .controls .checkbox').removeClass('selected')
      $('.controls .radio.default, .controls .checkbox.default').addClass('selected')
      $('#category-button').html('Toggle categories <div class="arrow-down"></div>').removeClass('active')
      $('#dropdown-domains .checkbox').addClass('selected')
      $('#dropdown-programs').select2('val', 'Highlight a program')
      $('.tooltip').css('opacity', '0')
      return false
    })
    $('.x, .hide-methodology').click(function(){
      $('.lower').fadeOut()
      $('body').removeClass('hidden')
      $(window).trigger('scroll')
    })
    $('.show-hide-story a').click(function(){
      $('.intro').fadeOut()
      $('body').removeClass('hidden')
      $(window).trigger('scroll')
      return false;
    })
    $('.show-hide-controls a').click(function(){
      $('.intro').fadeOut()
      $('body').removeClass('hidden')
      tempKillScroll = true
      // Reset
      $('#dropdown-domains .checkbox').addClass('selected')
      categorySelections = categories
      $('#category-button').html('Toggle categories <div class="arrow-down"></div>').removeClass('active')
      if(showingControls){
        $('body').removeClass('showing-controls')
        $('.top-button.show-hide-controls a').text('Explore data')
        $('.controls').fadeOut(d => {
          $('.main-slide,.viz,.source').fadeIn()
        })
        updateSlide('intro')
      } else {
        $('body').addClass('showing-controls')
        $('.top-button.show-hide-controls a').text('Return to story')
        $('.main-slide').fadeOut(d => {
          $(window).scrollTop(0)
          $('.controls-flex').css({
            height:window.innerHeight - $('.header').outerHeight() - $('.sub-header').outerHeight() - 30
          })
          $('.controls,.viz,.source').fadeIn()
          $('.controls .radio').removeClass('selected')
          $('.controls .radio.default').addClass('selected')
        })
        updateSlide('outro')
      }
      // reactivate Scroll after timeout
      setTimeout(function(){
        tempKillScroll = false
      }, defaultAnimationTime+20)
      showingControls = !showingControls
      return false;
    })
    // Expandable button with dropdown inside
    $('.expandable-button a.default-button').click(function(){
      $(this).parents('.expandable-button').find('.expanded-contents').toggle()
      return false;
    })
    $('div').click(function(){
      if($(this).hasClass('expandable-button') || $(this).parents('.expandable-button').length > 0){

      } else {
        $('.expanded-contents').hide()
        if(categorySelections.length == categories.length || categorySelections.length == 0){
          // All selected
          $('#category-button').removeClass('active')
          var buttonText = 'Toggle categories'
        } else if(categorySelections.length == 1){
          $('#category-button').addClass('active')
          var buttonText = categorySelections[0]
        } else {
          $('#category-button').addClass('active')
          var buttonText = categorySelections[0] + ' +' + (categorySelections.length-1)
        }
        $('#category-button').html(buttonText + ' <div class="arrow-down"></div>')
      }
    })
    // Control checkbox clicking
    $('.checkbox').on('mouseup', function(d){
      if($(this).hasClass('selected')){
        $(this).removeClass('selected')
      } else {
        $(this).addClass('selected')
      }
    })
    // Control radio clicking
    $('.radio').on('mouseup', function(d){
      if($(this).hasClass('selected') || $(this).hasClass('disabled')){
        // Do nothing
      } else {
        // Remove other selected from group
        $(this).parents('.radio-group').find('.radio').removeClass('selected')
        // Add selected here
        $(this).addClass('selected')
      }
    })
    // Show/hide labels
    $('.checkbox#show-labels').on('mouseup', function(d){
      setTimeout(() => {
        if($(this).hasClass('selected')){
          // Show labels
          showingLabels = true
        } else {
          // Hide labels
          showingLabels = false
        }
        updateViz()
      })
    })
    // Show/hide individual programs
    $('#controls .radio#show-individual-programs').on('mouseup', function(d){
      setTimeout(() => {
        if($(this).hasClass('selected')){
          // Show programs
          showingPrograms = true
          showingAverages = false
          // Switch to show program CI if we're showing average CI
          if(showingCI){
            showingProgramCI = true
            showingCI = false
          }
        }
        if(showingAverages && !showingPrograms){
          // Enabled average-only settings
          $('.average-only').removeClass('disabled')
        } else {
          $('.average-only').addClass('disabled')
        }
        updateViz()
      })
    })
    // Show/hide averages
    $('#controls .radio#show-average').on('mouseup', function(d){
      setTimeout(() => {
        if($(this).hasClass('selected')){
          // Show only averages
          showingAverages = true
          showingPrograms = false
          // Switch to show average CI if we're showing program CI
          if(showingProgramCI){
            showingProgramCI = false
            showingCI = true
          }
        }
        if(showingAverages && !showingPrograms){
          // Enabled average-only settings
          $('.average-only').removeClass('disabled')
        } else {
          $('.average-only').addClass('disabled')
        }
        updateViz()
      })
    })
    // Show/hide based on sample
    $('#controls .radio-group#samples .radio').on('mouseup', function(d){
      setTimeout(() => {
        sampleSel = $(this).data('value')
        updateViz()
      })
    })
    // Show/hide averages
    $('#controls .radio#show-both').on('mouseup', function(d){
      setTimeout(() => {
        if($(this).hasClass('selected')){
          // Remove from categories
          showingPrograms = true
          showingAverages = true
          // Switch to show average CI if we're showing program CI
          if(showingProgramCI){
            showingProgramCI = false
            showingCI = true
          }
        }
        if(showingAverages && !showingPrograms){
          // Enabled average-only settings
          $('.average-only').removeClass('disabled')
        } else {
          $('.average-only').addClass('disabled')
        }
        updateViz()
      })
    })
    // Show/hide confidence intervals
    $('.checkbox#show-confidence-intervals').on('mouseup', function(d){
      setTimeout(() => {
        if($(this).hasClass('selected')){
          // show the cI
          if(showingAverages){
            showingCI = true
            showingProgramCI = false
          } else {
            showingProgramCI = true
            showingCI = false
          }
        } else {
          //  Don't show the CI
          showingProgramCI = false
          showingCI = false
        }
        updateViz()
      })
    })
    // Change robustness
    $('#controls #dropdown-robustness').change(function(){
      setTimeout(() => {
        assumptionSel = '_rob' + $(this).val()
        updateViz()
      })
    })
    // Change Alterantive tax dopdown
    $('#controls #lbwtp-options').change(function(){
      setTimeout(() => {
        assumptionSel = $(this).data('value') + $(this).val()
        updateViz()
      })
    })
    // Controls
    $('#dropdown-domains .checkbox').not('.only').on('mouseup', function(e){
      e.stopPropagation();
      setTimeout(() => {
        if($(this).hasClass('show-hide-all')){
          if($(this).hasClass('selected')){
            $('#dropdown-domains .checkbox').addClass('selected')
            categorySelections = categories
          } else {
            $('#dropdown-domains .checkbox').removeClass('selected')
            categorySelections = []
          }
        } else {
          var allSelected = $('#dropdown-domains .checkbox.selected').length+1 == $('#dropdown-domains .checkbox').length
          if(allSelected){
            categorySelections = [$(this).data('domain')]
            $('#dropdown-domains .checkbox.selected').removeClass('selected')
            $(this).addClass('selected')
          } else {
            if($(this).hasClass('selected')){
              // Add to categories
              categorySelections.push($(this).data('domain'))
            } else {
              // Remove from categories
              categorySelections.splice(categorySelections.indexOf($(this).data('category')),1)
            }
          }
        }
        updateViz()
      })
    })
    $('#dropdown-domains .checkbox .only').on('mouseup', function(e){
      e.stopPropagation();
      setTimeout(() => {
        categorySelections = [$(this).parent().data('domain')]
        $('#dropdown-domains .checkbox').removeClass('selected')
        $(this).parent().addClass('selected')

        // Update checkboxes
        updateViz()
      })
      return false;
    })
    // Chane assumptions
    $('#controls #assumptions.radio-group .radio').on('mouseup', function(){
      setTimeout(() => {
        assumptionSel = $(this).data('value')
        // If this is the former LBTWP item, we need to append the matching dropdown
        if($(this).hasClass('lbtwp')){
          assumptionSel += $('#lbwtp-options').val()
        }
        if($(this).hasClass('rob_dropdown')){
          assumptionSel += $('#dropdown-robustness').val()
        }
        updateViz()
      })
    })
    $('.x-axis-values div').on('mouseup', function(d){
      d = $(this).data('sel')
      setTimeout(() => {
        // Remove selected class from other radios
        xSel = d
        updateViz()
      })
    })
    $('.y-axis-values div').on('mouseup', function(d){
      d = $(this).data('sel')
      // Remove selected class from other radios
      setTimeout(() => {
        ySel = d
        if(ySel == mvpfColumn || ySel == costColumn){
          $('.mvpf-cost-only').removeClass('disabled').find('select').prop('disabled', false)
        } else {
          $('.mvpf-cost-only').addClass('disabled').find('select').prop('disabled', true)
        }
        if(ySel != mvpfColumn){
          $('.mvpf-only').addClass('disabled')
        } else {
          $('.mvpf-only').removeClass('disabled')
        }
        updateViz()
      })
    })
    $('.jumpto').click(function(){
      $('body,html').animate({
        scrollTop:$('#controls').offset().top-50
      })
      return false;
    })

    // Scales with default values
    var yConvertN = (n) => {
      if(ySel.match(mvpfColumn)){
        if(isNaN(parseFloat(n))){
          return n == null || n == '' ? null : n == 'Inf' ? (6) : (-1)
        } else if(n > -1 && n < 5){
          return (n)
        } else if(n >= 5){
          return (5)
        } else if(n <= -1){
          return (-1)
        }
      } else if(ySel.match(costColumn)){
        if(n > -2 && n < 2){
          return (n)
        } else if(n <= -2){
          return (-2)
        } else if(n >= 2){
          return (2)
        }
      } else if(ySel.match(willingnessColumn)){
        if(n > -1 && n < 5){
          return (n)
        } else if(n <= -1){
          return (-1)
        } else if(n >= 5){
          return (5)
        }
      }
      return (n)
    }
    var x = d3.scaleLinear()
      .range([0,width])
      .domain(d3.extent(data, d => (d[xSel])))
    var ny = d3.scaleLinear()
      .range([height,0])
      .domain(d3.extent(data, d => yConvertN(d[ySel])))
    var y = (n) => {
      return ny(yConvertN(n))
    }


    // Redo data with simulation
    const simulation = d3.forceSimulation(processedData.filter(d => d[baselineColumn] == 1))
      .force('x', d3.forceX(d => x(d[xSel])).strength(1))
      .force('y', d3.forceY(height / 2))
      .force('collide', d3.forceCollide().radius(d => circleSize))
      .stop()

    for(var i = 0; i < 1000; i++){
      simulation.tick()
    }

    // Apply simulation data
    let origData = processedData.filter(d => d[baselineColumn] == 0).map(d => {
      d.y = height /2
      return d
    })
    data = simulation.nodes()
    data = data.concat(origData)

    // Add axes
    var xAxis = d3.axisBottom(x).tickValues(x.ticks(5))
    var xAxisGroup = svg
      .append('g')
        .attr('class', 'x-axis axis')
        .call(xAxis)
        .attr('transform', `translate(0,${height/2+60})`)
    var xAxisLabel = xAxisGroup.append('text')
      .attr('class', 'axis-label')
      .text(labelNames.get(xSel).description)
      .attr('x', width/2)
      .attr('y', margin.bottom/2+14)
    var xAxisLine = xAxisGroup.selectAll('line')
      .attr('y1', -height/4)
      .attr('y2', 0)
    var yAxisTicks = ny.ticks().filter(tick => Number.isInteger(tick))
    var yAxis = d3.axisLeft(ny)
        .tickValues(yAxisTicks)
    var yAxisGroup = svg
      .append('g')
        .attr('class', 'y-axis axis')
        .call(yAxis)
        .style('opacity', 0)
    var yAxisLabel = yAxisGroup.append('g')
      .attr('transform', `translate(${-45}, ${height/2}) rotate(-90)`)
      .append('text')
      .attr('class', 'axis-label')
      .text(labelNames.get(ySel).description)
    var yAxisLine = yAxisGroup.selectAll('line')
      .attr('x1', 0)
      .attr('x2', width)
      .filter(d => d === 0)
        .style('stroke', '#bfbfbf')

    // Data viz
    var dataGroup = svg.selectAll('g.datagroup')
      .data(data).enter()
        .append('g')
      .attr('class', 'datagroup')
      .attr('transform', d => forced ? `translate(${x(d[xSel])}, ${d.y})` : `translate(${x(d[xSel])}, ${y(d[ySel])})`)
      .style('opacity', 1)
      .on('mouseover', showTooltip)
      .on('mouseout', hideTooltip)

    var circles = dataGroup.append('circle')
      .attr('r', d => getCircleSize(d))
      .attr('fill', d => colorScale(d[parentCategoryColumn]))
      .attr('class', 'data-group-circle')
      .style('opacity', .75)
      .attr('stroke-width', 0)
      .attr('stroke', '#333')

    var highlightLabel = svg.append('g')
      .attr('class', 'highlight-label')
      .style('opacity', 1)

    // Opening animation
    var openingAnimation = true
    var openingCategorySelection = 0
    var openingT = null
    function startOpeningAnimation(){
      clearInterval(openingT)
      openingCategorySelection = 0
      openingT = setInterval(function(){
        animateOpening()
      },4000)
      animateOpening()
    }
    function animateOpening(){
      if(openingAnimation){
        var randomCircleSelection = upfrontHighlightsKeys[openingCategorySelection]
        var sel = data.filter(d => d[labelColumn] == randomCircleSelection)[0]
        killTooltipHover = false
        showTooltip(sel, true, true, upfrontHighlightsHashed.get(randomCircleSelection))
      } else {
        clearInterval(openingT)
        highlightLabel.style('opacity', 0)
      }
      openingCategorySelection++
      if(openingCategorySelection > upfrontHighlightsKeys.length) openingCategorySelection = 0
    }

    var programCIGroup = svg.selectAll('g.program-ci')
      .data(data).enter()
        .append('g')
          .attr('class', 'program-ci')

    programCIGroup
        .attr('transform', d => {
          var xAvg = x(d[xSel]) ? x(d[xSel]) : 0
          var yAvg = 0
          return `translate(${xAvg}, ${yAvg})`
        })
        .style('opacity', d => showingProgramCI ? 1 : 0)

    var programConfidenceInterval = programCIGroup.append('line')
      .attr('x1', d => 0)
      .attr('x2', d => 0)
      .attr('y1', d => y(d[ySel+lowerCI]))
      .attr('y2', d => y(d[ySel+upperCI]))
      .attr('stroke-width', 2)
      .style('opacity', .5)
      .attr('stroke', d => colorScale(d[parentCategoryColumn]))

    var averageGroup = svg.selectAll('g.avg')
      .data(averageData).enter()
        .append('g')
        .attr('class', 'avg')
        .style('opacity', d => showingAverages ? 1 : 0)
        .on('mouseover', showTooltip)
        .on('mouseout', hideTooltip)

    averageGroup
      .attr('transform', d => `translate(${x(d[xSel])}, ${y(d[ySel])})`)

    var averageCircles = averageGroup.append('circle')
      .attr('r', circleSize*1.5)
      .attr('fill', d => colorScale(d[parentCategoryColumn]))
      .attr('stroke-width', 0)
      .attr('stroke', '#333')

    var confidenceIntervalGroup = svg.selectAll('g.avg-ci')
      .data(averageData).enter()
        .append('g')
        .attr('class', 'avg-ci')

    confidenceIntervalGroup
        .attr('transform', d => `translate(${x(d[xSel])}, ${y(d[ySel])})`)
        .style('opacity', d => showingCI ? 1 : 0)

    var confidenceInterval = confidenceIntervalGroup.append('line')
      .attr('x1', d => 0)
      .attr('x2', d => 0)
      .attr('y1', d => y(d[ySel+lowerCI]) ? y(d[ySel+lowerCI])-y(d[ySel]) : 0)
      .attr('y2', d => y(d[ySel+upperCI]) ? y(d[ySel+upperCI])-y(d[ySel]) : 0)
      .attr('stroke-width', 3)
      .style('opacity', .5)
      .attr('stroke', d => colorScale(d[parentCategoryColumn]))

    // Create voronoi
    function flatten(dat){
      var ret = []
      var i = 0
      for(var d of dat){
        var thisYSel = d.group_average == 1 ? averageYSel !== null ? averageYSel : ySel : ySel
        var thisY = d.group_average ? y(d[thisYSel]) : y(d[ySel]) //: y(d[thisYSel])//d.y : y(d[thisYSel])
        ret.push([
          x(d[xSel]),
          thisY,
          d[idColumn]
        ])
        i++
      }
      return ret
    }

    // var preData = data
    var preData = data.concat(averageData)

    var voronoiData = d3.voronoi()
      .extent([[-1, -1], [width+1, height+1]])
      .polygons(flatten(preData))

    var avgVoronoiData = d3.voronoi()
      .extent([[-1, -1], [width+1, height+1]])
      .polygons(flatten(averageData))

    // Add voroni hover for idndividual programs
    var voronoi = svg.append('g')
      .attr('class', 'voronoi')
    var avgVoronoi = svg.append('g')
      .attr('class', 'avg-voronoi')
    var voronoiPaths = voronoi.selectAll(".voronoi-cell")
        .data(voronoiData)
      .enter().append("path")
        .attr("d", d => {
          for(var i in d){
            if(i != 'data'){
              d[i] = [d[i][0], d[i][1]]
            }
          }
          return d ? "M" + d.join("L") + "Z" : ""
        })
        .style('stroke','#333')
        .style('stroke-width', showVoronoi ? 1 : 0)
        .attr('class', 'voronoi-cell')
        .style("fill", d => 'transparent')
        .on('mousemove', showTooltip)
        .on('mouseout', hideTooltip)

      var avgVoronoiPaths = avgVoronoi.selectAll(".avg-voronoi-cell")
          .data(avgVoronoiData)
        .enter().append("path")
          .attr("d", d => {
            for(var i in d){
              if(i != 'data'){
                d[i] = [d[i][0], d[i][1]]
              }
            }
            return d ? "M" + d.join("L") + "Z" : ""
          })
          .style('stroke','#333')
          .style('stroke-width', showVoronoi ? 1 : 0)
          .attr('class', 'avg-voronoi-cell')
          .style("fill", d => 'transparent')
          .on('mousemove', showTooltip)
          .on('mouseout', hideTooltip)

    // // Append labels to voronoi
    var voronoiLabels = svg.selectAll('.vlabel')
        .data(preData).enter()
      .append('text')
        .attr('x', d => x(d[xSel]))
        .attr('y', d => forced ? d.y : y(d[ySel]))
        .attr('class', 'vlabel dedupe')
        .text(d => d[labelColumn] ? d[labelColumn] : d[categoryColumn])
        .style('opacity', 0)

    // Tooltips
    function makeLabelNumber(column, d){
      var n = d[column]
      n = n == null ? 'N/A' : isNaN(parseFloat(n)) ? n : column.match(ageColumn) ? Math.round( n * 10 ) / 10 : parseFloat(n).toFixed(2)
      if(n == 'Inf') n = 'Inf.'
      return n;
    }
    var lastTooltipId = null
    // **showtooltip
    function showTooltip(d, fromSelection, showLabel, forcedLabel){

      // Set up a new array identical to what we have in voronoi hover, on which this hover function is based. That way we can use this at any point.
      // Setting forced to true uses a direct id from var data
      if(fromSelection === true){
        id = d.id
      } else {
        id = d.data[2]
        d = d.data
      }
      if(id == lastTooltipId || d === undefined || !d || (forced && !forcedLabel) || killTooltipHover) {
        return false
      } else {

        lastTooltipId = id

        // Kill tooltip hover
        if(fromSelection === true) {
          killTooltipHover = true
        }

        // If we're showing averages, look for matches on the domain name, because that's what averages are. Otherwise look for matches on ID, because that's the program identifier.
        if(showingAverages || showingCI){
          var matchingDot = averageGroup.filter(k => {
            return k[idColumn] == id})
          if(matchingDot.size() == 0){
            var matchingDot = dataGroup.filter(k => k[idColumn] == id)
          }
        } else {
          var matchingDot = dataGroup.filter(k => k[idColumn] == id)
        }

        // If matching dot isn't showing, just ignore what we're doing
        if(matchingDot.style('opacity') != 1) return

        dataGroup.selectAll('circle')
          .attr('stroke-width', 0)
        matchingDot.selectAll('circle')
          .attr('stroke-width', 2)
          .attr('stroke', 'black')
          .moveToFront()

        // We're forcing a tooltip to show from somewhere else, like the controls, so highlight it so it can be seen more easily
        if(fromSelection === true){
          matchingDot.append('circle')
            .attr('r', 5)
            .attr('fill', mainColor)
            .style('opacity', 1)
            .transition().duration(defaultAnimationTime)
              .attr('r', 25)
              .style('opacity', 0)
              .on('end', function(d){
                // Ensure we don't highlight program dots when showing averages
                // highlightDot(dot, this, n)
                highlightDot(this.parentNode, this, 0, true)
              })
        }
        if(showLabel === true || forced){
          forcedLabelArr = forcedLabel.split('<br />')
          // We're showing label and not actual tooltip
          voronoiLabels.style('opacity', 0)
            .attr('class', 'vlabel dedupe')
          var vtarget = voronoiLabels.filter(d => d.id == id)
          var vextent = d3.extent(data, d => d.y)
          vtarget.each(function(d){
              highlightLabel
                .attr('transform', `translate(${x(d[xSel])},${d.y < height/2 ? vextent[0] : vextent[1]})`)
                .style('opacity', 1)
              highlightLabel.selectAll('text').remove()
              highlightLabel.selectAll('text')
                  .data(d.y < height/2 ? forcedLabelArr.reverse() : forcedLabelArr)
                .enter()
                .append('text')
                .text(d => d)
                .attr('class', 'label vhighlight')
                .attr('y', (k,i) => d.y < height/2 ? -15 - (14*i) - (1*i) : 15 + (14*i) + (1*i))
          })
        } else {
          matchingDot.each(d => {
            // Append any matching data to the tooltip display category
            for(var i in d){
              // Append assumptions to the tooltip
              var k = makeLabelNumber(i, d)
              $('.live[data-column="' + i + '"]').text(k)
            }
            // Append alt specs if exists
            if(sampleSel || assumptionSel){
              $('.meta-box.alt').show()
              $('.baseline-label').show()
              var $meta = $('.meta-box.alt')
              // If showing averages, we need to show a different column, so make the average-only column selector
              if(d.group_average == 1){
                ySelExt = sampleSel + assumptionSel
              } else {
                // For programs, we ignore the samepl beacuse that's only a facotr in showing/hiding
                ySelExt = assumptionSel
              }

              // Append robustness if selected
              if(assumptionSel == assumptionRobustness){
                ySel+=robustnessSel
              }
              if(d[mvpfColumn+ySelExt]){
                $meta.find('.live[data-column="' + mvpfColumn + '"]').text(makeLabelNumber( mvpfColumn+ySelExt, d))
                $meta.find('.live[data-column="' + mvpfColumn + '_l"]').text(makeLabelNumber(mvpfColumn+ySelExt+'_l', d))
                $meta.find('.live[data-column="' + mvpfColumn + '_u"]').text(makeLabelNumber(mvpfColumn+ySelExt+'_u', d))
              } else {
                $meta.find('.row-'+ mvpfColumn).hide()
              }
              if(d[costColumn+ySelExt]){
                $meta.find('.live[data-column="' + costColumn + '"]').text(makeLabelNumber(costColumn+ySelExt, d))
                $meta.find('.live[data-column="' + costColumn + '_l"]').text(makeLabelNumber(costColumn+ySelExt+'_l', d))
                $meta.find('.live[data-column="' + costColumn + '_u"]').text(makeLabelNumber(costColumn+ySelExt+'_u', d))
              } else {
                $meta.find('.row-'+ costColumn).hide()
              }
              if(d[willingnessColumn+ySelExt]){
                $meta.find('.live[data-column="' + willingnessColumn + '"]').text(makeLabelNumber(willingnessColumn+ySelExt, d))
                $meta.find('.live[data-column="' + willingnessColumn + '_l"]').text(makeLabelNumber(willingnessColumn+ySelExt+'_l', d))
                $meta.find('.live[data-column="' + willingnessColumn + '_u"]').text(makeLabelNumber(willingnessColumn+ySelExt+'_u', d))
              } else {
                $meta.find('.row-'+ willingnessColumn).hide()
              }
            } else {
              $('.meta-box.alt').hide()
              $('.baseline-label').hide()
            }
            // Show tooltip
            $('.tooltip')
              .css('opacity', 1)
              .css('left', k => {
                var tooltipX = x(d[xSel])+margin.left
                var right = true
                if(tooltipX + $('.tooltip').outerWidth() > width){
                  right = false
                  tooltipX = tooltipX - $('.tooltip').outerWidth()/2 - 25
                  tooltipX = tooltipX < 0 ? 0 : tooltipX
                }
                var tooltipY = forced ? d.y : y(d[ySel])+margin.top
                if(tooltipY > height/2){
                  tooltipY = tooltipY - $('.tooltip').outerHeight() - 25
                  tooltipX = tooltipY < 0 ? right ? tooltipX + 65 : 0 : tooltipX
                }
                // If Y will be 0 then force this to the left or right instead
                return tooltipX + 'px'
              })
              .css('top', k => {
                var tooltipY = forced ? d.y : y(d[ySel])+margin.top
                if(tooltipY > height/2){
                  tooltipY = tooltipY - $('.tooltip').outerHeight() - 25
                  tooltipY = tooltipY < 0 ? 0 : tooltipY
                }
                return tooltipY + 'px'
              })
            $('.tooltip tr ').show()
          })
        }
      }
    }
    function hideTooltip(d){
      if(!killTooltipHover){
        dataGroup
          .selectAll('circle')
        .attr('stroke-width', k => 0)
        averageGroup
          .selectAll('circle')
          .attr('stroke-width', k => 0)
        $('.tooltip').css('opacity', 0)
      }
    }

    function appendAssumptions() {
      // Remove any existing assumptions
      assumptions.forEach(d => {
        ySel = ySel.split(d)[0]
      })
      averageYSel = ySel + sampleSel + assumptionSel
      ySel += assumptionSel
    }
    function resizeViz () {
      // Update hegiht/width props
      getVizProps()
      // Update SVG elements
      svgW
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top+margin.bottom)
      svg
        .attr('transform',`translate(${margin.left},${margin.top})`)
      xAxisLabel
        .attr('x', width/2)
        .attr('y', margin.bottom/2+14)
      xAxisLine
        .attr('y1', -height/4)
      yAxisLine
        .attr('x2', width)
      // Update scales
      x.range([0,width])
      ny.range([height,0])
    }
    function highlightDot(dot, e, n, forceIt){
      d3.select(e).remove()
      d3.select(dot).append('circle')
        .attr('r', 5)
        .attr('fill', d => colorScale(d[parentCategoryColumn]))
        .style('opacity', .25)
        .transition().duration(defaultAnimationTime)
          .attr('r', 25)
          .style('opacity', 0)
          .on('end', function(d){
            if(forceIt || check != null && d.thisIn) {
              // Ensure we don't highlight program dots when showing averages
              if(forceIt || n < 2 && (d.group_average == 1 && showingAverages || d.group_average == 0) && showingPrograms && !showingAverages){
                n++
                highlightDot(dot, this, n)
              }
            }
          })
      d3.select(dot).selectAll('circle.data-group-circle')
        .moveToFront()
    }

    // Function that updates viz based on selections
    // **updateviz**
    function updateViz() {
        // console.log('** updating **')
        // console.log({
        //   applyCheckTo,
        //   sampleSel,
        //   check,
        //   checkValue,
        //   checkColumn,
        //   showingPrograms,
        //   showingLabels,
        //   showingCI,
        //   showingProgramCI,
        //   showingAverages,
        //   animate,
        //   forced,
        //   colorBy,
        //   lastSlide,
        //   ySel,
        //   xSel
        // })
      // Process selections to include assumptions
      appendAssumptions()

      if(!openingAnimation) {
        highlightLabel.transition().duration(defaultAnimationTime).style('opactiy', 0)
      }

      // Scales
      // Set extent using programs
      if(redoAxesBy != 'auto'){
        // Do by programs
        var xExtent = d3.extent(data, d => d[xSel])
        var yExtent = d3.extent(data, d => yConvertN(d[ySel]))
        // Update the domain with the latest
        x.domain(xExtent)
        ny.domain(yExtent)
      } else {
        // We've asked to redo the sizes, or we've decided any update coming from the lastSlide designated must be forced updated
        var xExtent = d3.extent(data, d => d[xSel])
        var yExtent = d3.extent(data, d => yConvertN(d[ySel]))
        if(showingAverages){
          // Check whether the average Scale is wider
          ySelActive = averageYSel !== null ? averageYSel : ySel
          var averageXExtent = d3.extent(averageData, d => d[xSel])
          var averageYExtent = d3.extent(averageData, d => yConvertN(d[ySelActive]))
          if(showingPrograms){
            xExtent[0] = d3.min([xExtent[0], averageXExtent[0]])
            xExtent[1] = d3.max([xExtent[1], averageXExtent[1]])
            yExtent[0] = d3.min([yExtent[0], averageYExtent[0]])
            yExtent[1] = d3.max([yExtent[1], averageYExtent[1]])
          } else {
            xExtent = averageXExtent
            yExtent = averageYExtent
          }
        }
        if(showingCI) {
          // Check whether the CI Scale is wider
          // Note that X axis is still defined by the average, since the confidence interval goes vertically and not horizontally
          ySelActive = averageYSel !== null ? averageYSel : ySel
          var CIXExtent = d3.extent(averageData, d => d[xSel])
          var CIYExtent = [d3.min(averageData, d => yConvertN(d[ySelActive+lowerCI])), d3.max(averageData, d => yConvertN(d[ySelActive+upperCI]))]
          if(showingPrograms || showingAverages){
            xExtent[0] = d3.min([xExtent[0], CIXExtent[0]])
            xExtent[1] = d3.max([xExtent[1], CIXExtent[1]])
            yExtent[0] = d3.min([yExtent[0], CIYExtent[0]])
            yExtent[1] = d3.max([yExtent[1], CIYExtent[1]])
          } else {
            xExtent = CIXExtent
            yExtent = CIYExtent
          }
        }
        if(showingProgramCI){
          // Check whether program CI scale is wider
          var CIXExtent = d3.extent(data, d => d[xSel])
          var CIYExtent = [d3.min(data, d => yConvertN(d[ySel+avgLabel+lowerCI])), d3.max(data, d => yConvertN(d[ySel+avgLabel+upperCI]))]
          if(showingPrograms || showingAverages){
            xExtent[0] = d3.min([xExtent[0], CIXExtent[0]])
            xExtent[1] = d3.max([xExtent[1], CIXExtent[1]])
            yExtent[0] = d3.min([yExtent[0], CIYExtent[0]])
            yExtent[1] = d3.max([yExtent[1], CIYExtent[1]])
          } else {
            xExtent = CIXExtent
            yExtent = CIYExtent
          }
        }

        // Update the domain with the latest
        x.domain(xExtent)
        ny.domain(yExtent)

      } // end check on redoAxes




      /* -- CIRCLES -- */


      // Move and hide/show data
      dataGroup.transition().duration(defaultAnimationTime)
        .attr('transform', function(d){
          if(forced){
            // If forced var is true, we're using a simulation, so transform to a fixed x and y determined by the simulation
            return `translate(${x(d[xSel])}, ${d.y})`
          } else if(animate){
            // If animated and we're showing programs, use the "match" value stored during the animation check. Do this here because we adjust the scale after the initial animation to fit any new extents
            if(showingAverages){
              // TODO temp switch
              return d.match ? `translate(${x(d.match[xSel])}, ${y(d.match[ySel])})` : `translate(0, 0)`
            } else {
              // If we're not showing averages, we want to show the real program position, not where the averages were. This is step 2 of the earlier animation
              return `translate(${x(d[xSel])}, ${y(d[ySel])})`
            }
          } else {
            return `translate(${x((d[xSel]))}, ${y((d[ySel]))})`
          }
        })
        .style('opacity', function(d){
          if(showingPrograms){
            return 1
          } else{
            return 0;
          }
        })

        // PROGAMS Assign fill and opacity as values in the data for use elsehwere, highlighting anything selected as a highlight
        circles.each(d => {
          var thisIn = true
          // exclude if it's not in existing category or if the value is null
          if(categorySelections.indexOf(d[categoryColumn]) == -1 || yConvertN(d[ySel]) === null){
            thisIn = -1
          } else if(sampleSel == '' && d[baselineColumn] == 0){
            // Always hide baseline=0 when baseline is selected
            thisIn = -1
          } else if(sampleSel == '_res' && d[restrictedColumn] == 0){
            thisIn = -1
          } else if(check == null || applyCheckTo.indexOf('programs') == -1){
            // There is no specific check, or the check is not for programs, so always show the circle (group show/hide handled at group level)
            thisIn = true
          } else {
            var checks = []
            thatIn = false
            for(var i = 0; i < check.length; i++){
              var ch = check[i]
              var chCol = checkColumn[i]
              var chVal = checkValue[i]
              // There is a specific check, so perform that check
              if(ch == 'indexOf'){
                // thatIn = false
                for(var val of chVal){
                  if(val == d[chCol]) thatIn = true
                }
              } else if(ch == 'lt'){
                var thatIn = chCol == mvpfColumn ? yConvertN(d[chCol]) < chVal : d[chCol] < chVal
              } else if(ch == 'gt'){
                var thatIn = chCol == mvpfColumn ? yConvertN(d[chCol]) > chVal : d[chCol] > chVal
              } else if(ch == 'equals'){
                var thatIn = yConvertN(d[chCol]) == chVal
              } else if(ch == 'bw'){
                var thatIn = yConvertN(d[chCol]) >= chVal[0] && yConvertN(d[chCol]) <= chVal[1]
              }
              checks.push(thatIn)
            }
            // Set it to false if any of the checks came back false
            for(var ch of checks){
              if(ch != true) thisIn = false
            }
          }
          d.opacity = thisIn === true ? 1 : thisIn == -1 ? 0 : showingPrograms ? 1 : 0
          // Decide the fill, which is a little more complicated because of average and CI layers
          // if(alwaysShowColor){
          //   d.fill = colorScale(d[parentCategoryColumn])
          // } else
          if(thisIn === true){
            // This node is included
            if(colorBy != parentCategoryColumn){
              // We're showing based on a single color defined in updateViz
              d.fill = mainColor
            } else {
              // We're showing by parentCategoryColumn
              if(showingAverages || showingCI){
                // We have other layers showing, so to hide this better we'll make gray
                if(check == null || applyCheckTo.indexOf('programs') == -1){
                  d.fill = '#eee'
                } else {
                  d.fill = colorScale(d[parentCategoryColumn])
                }
              } else {
                // We're coloring based on category
                d.fill = colorScale(d[parentCategoryColumn])
              }
            }
          } else {
            // This node isn't included
            if(showingPrograms){
              // We're showing programs, so make this one unhighlighted gray
              d.fill = '#eee'
            } else {
              // We're not showing programs, so default to category color
              d.fill = colorScale(d[parentCategoryColumn])
            }
          }
          d.thisIn = d.fill != '#eee' && d.opacity != 0
        })
        // Apply that to actual objects
        circles.transition().duration(defaultAnimationTime)
          .attr('stroke-width', d => d[programColumn] == programHighlight ? 2 : 0)
          .style('opacity', d => d.opacity)
          .attr('fill', d => alwaysShowColor ? colorScale(d[parentCategoryColumn]) : d.fill)

        // Move PROGRAMS CONFIDENCE INTERVAL
        programCIGroup.transition().duration(defaultAnimationTime)
          .attr('transform', d => {
            var xAvg = x(d[xSel]) ? x(d[xSel]) : 0
            var yAvg = 0//y(d[ySel+avgLabel]) ? y(d[ySel+avgLabel]) : 0
            return `translate(${xAvg}, ${yAvg})`
          })
          .style('opacity', d => showingProgramCI && d.thisIn ? 1 : 0)

        programConfidenceInterval.transition().duration(defaultAnimationTime)
          .attr('y1', d => y(d[ySel+lowerCI]))
          .attr('y2', d => y(d[ySel+upperCI]))


      /* -- AVERAGES -- */


      // Move averages
      var averageYSelActive = averageYSel !== null ? averageYSel : ySel
      averageGroup.transition().duration(defaultAnimationTime)
        .attr('transform', function(d){
          var xAvg = x(d[xSel])
          var yAvg = y(d[averageYSelActive])
          // Show latest or if Y doesn't exist show current
          return d[averageYSelActive] ? `translate(${xAvg}, ${yAvg})` : d3.select(this).attr('transform')
        })
        .style('opacity', d => {
          // Show programs, and then decide whether to show by category
          if(showingAverages){
            if(categorySelections.indexOf(d[categoryColumn]) > -1 && d[averageYSelActive]){
              return 1
            } else {
              return 0
            }
          } else {
            return 0
          }
        })

      // AVERAGE: Assign fill and opacity properties to the data for use in many other parts later
      averageCircles.each(d => {
        var thisIn = true
        if(check == null || applyCheckTo.indexOf('averages') == -1){
          // There is no specific check, so always show (show/hide handled at group level)
          thisIn = true
        } else {
          var checks = []
          for(var i = 0; i < check.length; i++){
            var ch = check[i]
            var chCol = checkColumn[i]
            var chVal = checkValue[i]
            // There is a specific check, so perform that check
            if(ch == 'indexOf'){
              var thatIn = false
              for(var val of chVal){
                if(val == d[chCol]) thatIn = true
              }
            } else if(ch == 'lt'){
              var thatIn = d[chCol] < chVal
            } else if(ch == 'gt'){
              var thatIn = d[chCol] > chVal
            } else if(ch == 'equals'){
              var thatIn = d[chCol] == chVal
            } else if(ch == 'bw'){
              var thatIn = d[chCol] >= chVal[0] && d[chCol] <= chVal[1]
            }
            checks.push(thatIn)
          }
          for(var ch of checks){
            if(ch != true) thisIn = false
          }
        }
        // showingAverages means we should semi-hide this, which we do through color and not opacity
        d.opacity = thisIn ? 1 : showingAverages ? 1 : 0
        d.fill = thisIn ? colorScale(d[parentCategoryColumn]) : showingAverages ? '#eee' : colorScale(d[parentCategoryColumn])
        d.thisIn = thisIn
      })

      // AVEARAGE: Apply those values to the actual objects
      averageCircles.transition().duration(defaultAnimationTime)
        .style('opacity', d => d.opacity)
        .attr('fill', d => alwaysShowColor ? colorScale(d[parentCategoryColumn]) : d.fill)

      // AVERAGE CI: Simple show/hide of confidence intervals
      confidenceIntervalGroup.transition().duration(defaultAnimationTime)
        .attr('transform', d => `translate(${x(d[xSel])}, ${0})`)
        .style('opacity', d => {
          // Show programs, and then decide whether to show by category
          if(showingCI){
            if(categorySelections.indexOf(d[categoryColumn]) > -1){
              return 1
            } else {
              return 0
            }
          } else {
            return 0
          }
        })
      // Move AVERAGE CI
      confidenceInterval.transition().duration(defaultAnimationTime)
        .attr('x1', d => 0)
        .attr('x2', d => 0)
        .attr('y1', d => y(d[averageYSelActive+lowerCI]) ? y(d[averageYSelActive+lowerCI]) : 0)
        .attr('y2', d => y(d[averageYSelActive+upperCI]) ? y(d[averageYSelActive+upperCI]) : 0)
        .attr('stroke', d => alwaysShowColor ? colorScale(d[parentCategoryColumn]) : d.fill)
        // .style('opacity', d => d.opacity)


      // If in and we're highlighting only some parentCategories, add a growing highlight circle animation
      if(check != null && highlightDots){
        if(showingPrograms && !showingAverages){
          dataGroup.filter(d => {
            return d.thisIn
          })
            .append('circle')
            .attr('r', circleSize)
            .attr('fill', d => colorScale(d[parentCategoryColumn]))
            .style('opacity', .25)
            .transition().duration(defaultAnimationTime)
              .attr('r', circleSize * 6)
              .style('opacity', 0)
              .on('end', function(){
                highlightDot(this.parentNode, this, 0)
              })
          }
          if(showingAverages) {
            averageGroup.filter(d => {
              return d.thisIn
            })
              .append('circle')
              .attr('r', circleSize)
              .attr('fill', d => colorScale(d[parentCategoryColumn]))
              .style('opacity', .25)
              .transition().duration(defaultAnimationTime)
                .attr('r', circleSize * 6)
                .style('opacity', 0)
                .on('end', function(){
                  highlightDot(this.parentNode, this, 0)
                })
          }

      }

      // Regenerate axes
      yAxisTicks = ny.ticks().filter(tick => Number.isInteger(tick))
      yAxis = d3.axisLeft(ny)
          .tickValues(yAxisTicks)

      xAxisGroup.call(xAxis.tickValues(x.ticks(5)))
      yAxisGroup.call(yAxis)

      yAxisGroup.selectAll('line')
        .attr('x1', 0)
        .attr('x2', width)
      xAxisGroup.selectAll('line')
        .attr('y1', -height)
        .attr('y2', 0)

      // Apply special formatting to axes
      if(xSel == yearColumn){
        // This is the year value, so we don't want any commas
        xAxisGroup.selectAll('.tick text')
          .text(d => parseInt(d))
      }
      // Ensure baseline 0 is darker
      yAxisGroup.selectAll('.tick').filter(d => d === 0)
        .select('line')
        .style('stroke', '#bfbfbf')

      // Ensure MVPF includes special formatting
      svg.selectAll('.mvpf-tick').remove()

      if(ySel.match(mvpfColumn) !== null){
        yAxisGroup.selectAll('.tick').filter(d => d == 5)
          .select('text')
          .text('>5.0')
        var top = yAxisGroup.selectAll('.tick').filter(d => d == 6)
        top.select('text')
          .text('∞')
        top.select('line')
          .style('stroke', mainColor)
        top.append('path')
          .attr('class', 'mvpf-tick')
          .attr('d', 'M -5,10 L 5,0')
          .style('stroke', '#bdbfbf')
          .attr('transform', `translate(0, ${ny(5.5)-5})`)

        top.append('path')
          .attr('class', 'mvpf-tick')
          .attr('d', 'M -5,10 L 5,0')
          .attr('transform', `translate(0, ${ny(5.5)})`)
          .style('stroke', '#bdbfbf')
      }

      // Reassign axis Labels
      xAxisLabel.text(labelNames.get(xSel).description)
      var yLabel = labelNames.get(averageYSelActive) ? labelNames.get(averageYSelActive).description : 'TK'
      yAxisLabel.text(yLabel)

      // Move and show/hide axes
      if(forced){
        yAxisGroup.transition().duration(defaultAnimationTime)
          .style('opacity', 0)
        xAxisGroup.transition().duration(defaultAnimationTime)
          .attr('transform', `translate(0,${height/2+60})`)
        xAxisGroup.selectAll('line')
          .attr('y1', -height/4)
          .attr('y2', 0)
        startOpeningAnimation()
      } else {
        yAxisGroup.transition().duration(defaultAnimationTime)
          .style('opacity', 1)
        xAxisGroup.transition().duration(defaultAnimationTime)
          .attr('transform', `translate(0,${height})`)
      }

      // Regenerate voronoi used combined average and program data, useful so we can hover over things
      // if(forced){
      //   var preData = data
      // } else if(showingAverages && !showingPrograms){
      //   var preData = averageData
      // } else {
      //   var preData = data.concat(averageData)
      // }

      var voronoiData = d3.voronoi()
        .extent([[-1, -1], [width+1, height+1]])
        .polygons(flatten(preData))

      var avgVoronoiData = d3.voronoi()
        .extent([[-1, -1], [width+1, height+1]])
        .polygons(flatten(averageData))

      var voronoiPaths = voronoi.selectAll('.voronoi-cell').data(voronoiData)

      var avgVoronoiPaths = avgVoronoi.selectAll('.avg-voronoi-cell').data(avgVoronoiData)

      voronoiPaths.exit().remove()

      voronoiPaths.enter().append('path')
          .attr('d', 'M 0,0, L 1,1 Z')
          .style('stroke','#333')
          .style('stroke-width', showVoronoi ? 1 : 0)
          .attr('class', 'voronoi-cell')
          .style("fill", d => 'transparent')
          .on('mousemove', showTooltip)
          .on('mouseout', hideTooltip)

      avgVoronoiPaths.exit().remove()

      avgVoronoiPaths.enter().append('path')
          .attr('d', 'M 0,0, L 1,1 Z')
          .style('stroke','#333')
          .style('stroke-width', showVoronoi ? 1 : 0)
          .attr('class', 'avg-voronoi-cell')
          .style("fill", d => 'transparent')
          .on('mousemove', showTooltip)
          .on('mouseout', hideTooltip)

      voronoiPaths = voronoi.selectAll('.voronoi-cell')

      voronoiPaths
          .attr("d", d => {
            for(var i in d){
              if(i != 'data'){
                d[i] = [d[i][0], d[i][1]]
              }
            }
            return d ? "M" + d.join("L") + "Z" : ""
          })

      avgVoronoiPaths = avgVoronoi.selectAll('.avg-voronoi-cell')

      avgVoronoiPaths
          .attr("d", d => {
            for(var i in d){
              if(i != 'data'){
                d[i] = [d[i][0], d[i][1]]
              }
            }
            return d ? "M" + d.join("L") + "Z" : ""
          })

      voronoiLabels = svg.selectAll('.vlabel').data(preData)

      voronoiLabels.exit().remove()

      voronoiLabels.enter().append('text')
        .attr('x', d => x(d[xSel]))
        .attr('y', d => {
          ySelActive = d.group_average == 1 ? averageYSel !== null ? averageYSel : ySel : ySel
          forced ? d.y : y(d[ySelActive])
        })
        .attr('class', 'vlabel dedupe')
        .style('opacity', 0)

      // Reposition labels according to voronoi
      labelHighlightsHashed = d3.map(labelHighlights, d => d.label)
      svg.selectAll('.vlabel')
          .text(d => d[labelColumn] ? d[labelColumn] : d[categoryColumn])
          .attr('class', 'vlabel dedupe') // Removes highlight class
          .each(function(d) {

              // Adjust for average
              var ySelActive = d.group_average ? averageYSel !== null ? averageYSel : ySel : ySel

              // Get Voronoi that matches
              var matchingVoronoi = voronoiPaths.filter(k => k ? k.data[2] == d.id : false)

              // Determine where to place the label for the best fit
              var [dx, dy] = [x(d[xSel]), y(d[ySelActive])]
              var [cx, cy] = [null, null]
              var pArea = 0
              matchingVoronoi.each(function(d){
                cx = d3.polygonCentroid(d)[0]
                cy = d3.polygonCentroid(d)[1]
                vBBox = this.getBBox()
                pArea = d3.polygonArea(d)
              })

              let angle = Math.round(Math.atan2(cy - dy, cx - dx) / Math.PI * 2);
              let orientSel = angle === 0 ? 'right'
                : angle === -1 ? 'top'
                : angle === 1 ? 'bottom'
                : 'left'

              // Determine whether to show or hide dependnig on whether label "fits" voronoi
              // But, if we're filtering, always show the label and hide via dedeupe instead
              // First check by label, since if we've defined a label we definitely want to show it
              var inOrOut = labelHighlights ? d.group_average === 0 ? labelHighlightsHashed.get(d[labelColumn]) : labelHighlightsHashed.get(d[categoryColumn]) : false
              if(labelHighlights){
                if(inOrOut){
                  labelOpacity = 1
                  orientSel = d.group_average === 0 ? labelHighlightsHashed.get(d[labelColumn]).position : labelHighlightsHashed.get(d[categoryColumn]).position
                } else {
                  labelOpacity = 0
                }
              } else if(!d.thisIn || (d.group_average == 1 && !showingAverages)){
                // Then check whether it's already out, or if this is an average dot and we're not showing averages yet, it's definitely out
                labelOpacity = 0
              } else {
                 if((showingAverages || showingCI) && d.group_average == 0){
                    // This is a program when we're showing averages, so never show the label
                    labelOpacity = 0
                  } else if(pArea < 2000 || categorySelections.indexOf(d[categoryColumn]) == -1){
                    // Too small a space to show a lable
                    labelOpacity = 0
                  } else if(showingLabels){
                    // It fits! and We're showing labels! So now determine based on type of labels to show
                    if(showingPrograms){
                      labelOpacity = 1
                    } else if(d.group_average == 1 && showingAverages){
                      labelOpacity = 1
                    } else {
                      labelOpacity = 0
                    }
                  } else {
                    // We're not even showing labels, so this was an exercise in futility
                    labelOpacity = 0
                  }
              }

              var orientOption = d.group_average === 0 ? orientValues : averageOrient

              // sHow/hide
              d3.select(this)
                .style('opacity', labelOpacity)

              d.origPos = {
                dx: d3.select(this).attr('dx'),
                dy: d3.select(this).attr('dy'),
                x: d3.select(this).attr('x'),
                y: d3.select(this).attr('y')
              }
              d.endPos = {
                dx: orientOption[orientSel]['dx'],
                dy: orientOption[orientSel]['dy'],
                x: x(d[xSel]),
                y: forced ? d.y : y(d[ySelActive])
              }

              d3.select(this)
                .attr('dx', orientOption[orientSel]['dx'])
                .attr('dy', orientOption[orientSel]['dy'])
                .attr('text-anchor', orientOption[orientSel]['textAnchor'])
                .attr('x', d => x(d[xSel]))
                .attr('y', d => forced ? d.y : y(d[ySelActive]))
          })

      // // Now that we've moved the labels, dedupee them
      if(!labelHighlights) svg.selectAll('.dedupe').dedupeLabels()

      // Then reposition them via animation
      voronoiLabels
          .each(function(d) {
            // sHow/hide
            d3.select(this)
            .attr('dx', d.origPos.dx)
            .attr('dy', d.origPos.dy)
            .attr('x', d.origPos.x)
            .attr('y', d.origPos.y)

            d3.select(this)
                .transition().duration(defaultAnimationTime)
              .attr('dx', d.endPos.dx)
              .attr('dy', d.endPos.dy)
              .attr('x', d.endPos.x)
              .attr('y', d.endPos.y)
          })

    // Move to front
    dataGroup.filter(d => d.thisIn)
      .moveToFront()
    averageGroup.filter(d => d.thisIn)
      .moveToFront()
    voronoiLabels
      .moveToFront()

    if(showingAverages || showingCI){
      $('.avg-voronoi').removeClass('no-hover')
      $('.voronoi').addClass('no-hover')
    } else {
      $('.voronoi').removeClass('no-hover')
      $('.avg-voronoi').addClass('no-hover')
    }

      // Remove the tooltip, which should always disappear when we're updating the viz
      hideTooltip()

    } // end updateViz()

    var updateSlide = (type) => {
      // Defaults
      categorySelections = categories
      applyCheckTo = null
      check = null
      showingPrograms = true
      showingLabels = true
      showingCI = false
      showingProgramCI = false
      showingAverages = false
      animate = false
      forced = false
      redoAxesBy = 'auto'
      colorBy = parentCategoryColumn
      ySel = mvpfColumn
      averageYSel = null
      xSel = ageColumn
      openingAnimation = false
      labelHighlights = null
      sampleSel = ''
      assumptionSel = ''
      highlightDots = true
      alwaysShowColor = false
      // Set attributes by slide
      // **switch
      switch(type){
        case 'intro':
          // #1
          forced = true
          openingAnimation = true
          xSel = yearColumn
          showingLabels = false
          highlightDots = false
          break;
        case 'mvpf':
          // #2
          alwaysShowColor = true
          xSel = yearColumn
          applyCheckTo = ['programs']
          checkColumn = [labelColumn]
          check = ['indexOf']
          checkValue = [['MA Scholarship']]
          labelHighlights = [{
            label: 'MA Scholarship',
            position: 'bottom'
          }]
          break;
        case 'pre-methodology':
          xSel = yearColumn
          applyCheckTo = ['programs']
          checkColumn = [mvpfColumn]
          check = ['indexOf']
          checkValue = [['Inf']]
          labelHighlights = [{
            label: 'FIU GPA',
            position: 'bottom'
          }]
          break;
        case 'age':
          // #3
          alwaysShowColor = true
          applyCheckTo = ['programs']
          checkColumn = [ageColumn, mvpfColumn]
          check = ['lt', 'gt']
          checkValue = [23, 4.9]
          labelHighlights = [{
            label: 'Perry Preschool',
            position: 'top'
          },{
            label: 'Texas Pell',
            position: 'bottom'
          },{
            label: 'K12 Spend',
            position: 'bottom'
          }]
          break
        case 'individual programs confidence intervals':
          // #4
          showingProgramCI = true
          break;
        case 'cost-medicaid':
          alwaysShowColor = true
          ySel = costColumn
          redoAxesBy = 'programs'
          showingAverages = true
          showingPrograms = false
          applyCheckTo =['averages']
          check = ['indexOf']
          checkColumn = [categoryColumn]
          checkValue = [['Child Education','Health Child','College Kids']]
          break;
        case 'average dots with confidence intervals':
          showingCI = true
          redoAxesBy = 'programs'
          showingAverages = true
          showingPrograms = false
          animate = true
          redoAxesBy = 'programs'
// Neighborhoods
          labelHighlights = [{
            label: 'Child Education',
            position: 'left'
          },{
            label: 'Health Child',
            position: 'top'
          },{
            label: 'College Child',
            position: 'right'
          },{
            label: 'Job Training',
            position: 'left'
          },{
            label: 'Housing Vouchers',
            position: 'left'
          },{
            label: 'Cash Transfers',
            position: 'top'
          },{
            label: 'Supp. Sec. Inc.',
            position: 'top-right'
          },{
            label: 'Nutrition',
            position: 'bottom'
          },{
            label: 'Unemp. Ins.',
            position: 'right'
          },{
            label: 'College Adult',
            position: 'right'
          },{
            label: 'Top Taxes',
            position: 'right'
          },{
            label: 'Disability Ins.',
            position: 'right'
          },{
            label: 'Health Adult',
            position: 'bottom'
          }]
          break
        case 'average dots only':
          // #5
          animate = true
          redoAxesBy = 'programs'
          showingPrograms = false
          showingAverages = true
          break;
        case 'average dots children':
          // #6
          redoAxesBy = 'programs'
          showingPrograms = false
          showingAverages = true
          showingCI = true
          applyCheckTo = ['averages']
          check = ['lt']
          checkColumn = [xSel]
          checkValue = [20]
          break;
        case 'age of beneficiaries highlight over 20':
          // #7
          applyCheckTo = ['programs', 'programs']
          check = ['gt', 'bw']
          checkColumn = [xSel, ySel]
          checkValue = [20, [.5,2]]
          break;
        case 'infinite mvpf':
          // #8
          animate = true
          applyCheckTo = ['programs']
          checkColumn = [mvpfColumn, ageColumn]
          check = ['indexOf', 'lt']
          checkValue = [['Inf'], 25]
          labelHighlights = [{
            label: 'MC Pregnant & Infants',
            position: 'bottom'
          },{
            label: 'MTO',
            position: 'bottom'
          },{
            label: 'Texas Pell',
            position: 'bottom'
          }]
          break
        case 'age of beneficiaries':
          // #8
          showingAverages = true
          checkColumn = [categoryColumn]
          applyCheckTo = ['averages', 'programs']
          check = ['indexOf']
          checkValue = [['Health Child']]
          ySel = costColumn
          break;
        case 'individual programs job training ssi':
          // #9
          check = ['indexOf']
          applyCheckTo = ['programs']
          checkColumn = [programColumn]
          checkValue = [["ssi_review", "job_corps"]]
          labelHighlights = [{
            label: 'Job Corps',
            position: 'left'
          },{
            label: 'SSI Review',
            position: 'left'
          }]
          break;
        case 'individual programs MTO':
          // #10
          check = ['indexOf']
          applyCheckTo = ['programs']
          checkColumn = [programColumn]
          checkValue = [["mto_all"]]
          labelHighlights = [{
            label: 'MTO',
            position: 'bottom'
          }]
          break;
        case 'outro':
          // Use defaults
          break;
        case 'In Kind Transfers':
          // #1
          forced = true
          openingAnimation = true
          xSel = yearColumn
          showingLabels = false
          check = ['indexOf']
          applyCheckTo = ['programs']
          checkColumn = [groupColumn]
          checkValue = [[type]]
          break;
      }
      // Make changes to show/hide value
      updateViz()
      // Store the last slide for future consideration
      lastSlide = type
    } //end of updateSlide

    var waypointOffset = window.innerWidth < 600 ? 75 : 50
    var waypointOffsetUp = window.innerWidth < 600 ? 25 : 0

    $('.main-slide').each(function(){
      var id = 'id'+Math.round(Math.random()*100000)
      $(this).attr('id', id)
      var waypoint = new Waypoint({
        element: document.getElementById(id),
        handler: (direction) => {
          if(direction === 'down' && !tempKillScroll && !showingControls){
            $('.main .slide').removeClass('active')
            $(this).addClass('active')
            updateSlide($(this).data('type'))
          }
        },
        offset: waypointOffset + '%'
      })
      var waypoint = new Waypoint({
        element: document.getElementById(id),
        handler: (direction) => {
          if(direction === 'up' && !tempKillScroll && !showingControls){
            $('.main .slide').removeClass('active')
            $(this).addClass('active')
            updateSlide($(this).data('type'))
          }
        },
        offset: waypointOffsetUp + '%'
      })

    })

  }// End of build

  var makeBarChart = (bcDataArr, bc2DataArr) => {

    // Chart vars
    let bcDataRaw = bcDataArr
    let bc2DataRaw = bc2DataArr

    let bcMargin = window.innerWidth < 600 ? [20, 10, 10, 10] : [5,5,5,5]
    var bcSelector = '.methodology-viz'
    $('.methodology-viz').empty()
    var selectorWidth, selectorHeight
    d3.select(bcSelector).each(function(){
      bcSelectorWidth = this.getBoundingClientRect().width
    })
    let bcWidth = bcSelectorWidth - bcMargin[1] - bcMargin[3];
    let bcHeight = $('#methodology-scroll').outerHeight() - bcMargin[0] - bcMargin[2]
    $('.methodology-header').outerHeight()
        bcHeight = window.innerWidth < 600 ? $('#methodology-scroll').outerHeight()/2 - $('.methodology-header').outerHeight() - bcMargin[0] - bcMargin[2] : bcHeight

    // Main svg
    const methSvg = d3.select('.methodology-viz').append('svg')
      .attr('width', bcWidth+ bcMargin[1] +bcMargin[3])
      .attr('height', bcHeight+ bcMargin[0] + bcMargin[2])

    const bcSvg = methSvg.append('g')

    const bc2Svg = methSvg.append('g')

    var xAxis = bcSvg.append('rect')
      .attr('width', bcWidth)
      .attr('height', 1)
      .attr('fill', axisColor)
      .attr('y', bcHeight / 2 -.5)

    var xAxis = bc2Svg.append('rect')
      .attr('width', bcWidth)
      .attr('height', 1)
      .attr('fill', axisColor)
      .attr('y', bcHeight / 2 -.5)

    const bcMaxYVal = window.innerWidth < 600 ? 44000 : 22000//d3.max(bcDataRaw, d => Math.abs(d.val))*2
    let bcX = d3.scaleLinear().domain([0,6]).range([0, bcWidth])
    let bcH = d3.scaleLinear().domain([0, bcMaxYVal]).range([0, bcHeight/2])
    let bcY = d3.scaleLinear().domain([0, bcMaxYVal]).range([bcHeight/2, 0])

    const bcMaxYVal2 = 160000 //d3.max(bc2DataRaw, d => d.val)*2
    let bcX2 = d3.scaleLinear().domain([0,6]).range([0, bcWidth])
    let bcH2 = d3.scaleLinear().domain([0, bcMaxYVal2]).range([0, bcHeight/2])
    let bcY2 = d3.scaleLinear().domain([0, bcMaxYVal2]).range([bcHeight/2, 0])

    // Scales
    function makeByChartByData(bcSvg, bcData, bcXThis, bcYThis, bcHThis){
      let barMargin = 5

      let barGroups = bcSvg.selectAll('g.bars')
        .data(bcData).enter()
      .append('g')
        .attr('class', 'bars bc-g')
        .attr('transform', (d,i) => {
          var y = bcYThis(d.runningTotal)
          return `translate(${bcXThis(i)}, ${y})`
        })

      let barLabels = barGroups.append('text')
        .text(d => {
          var neg = d.val < 0 ? '-' : ''
          return neg+'$'+numberWithCommas(Math.round(Math.abs(Number(d.val))).toLocaleString('en'))})
        .attr('y', d => d.val > 0 ? -5 : bcHThis(Math.abs(d.val))+15)
        .attr('x', bcXThis(1)/2)
        .attr('class', 'label label-num bc-text')
        .style('opacity', 0)

      let barNames = barGroups.append('g')
        .attr('class', 'label-name-group')
        .style('opacity', 0)
        .attr('transform', d => `translate(${bcXThis(1)/2}, ${d.val > 0 ? bcHThis(Math.abs(d.val))+15 : -5 - (12*(d.labelArr.length-1)) })`)
        .selectAll('.label-name')
          .data(d => d.labelArr).enter()
        .append('text')
          .text(d => d)
          .attr('class', 'label label-name bc-text')
          .attr('y', (d,i) => i*12)

      let bars = barGroups.append('rect')
        .attr('class', 'bc-rect')
        .attr('width', bcXThis(1)-barMargin*2)
        .attr('height', 0)
        .attr('x', barMargin)
        .attr('transform', d => d.val < 0 ? 'translate(0, 0)' : `translate(0, ${bcHThis(d.val)})`)
        .attr('fill', (d,i) => d.val > 0 ? mainColor : '#0074A2')
    }

    // Update slide
    function showLine(selArr){
      var sel = selArr.show
      var highlight = selArr.highlights
      lineChart.selectAll('path').transition().duration(defaultAnimationTime)
        .style('opacity', function(d){
          return d3.select(this).attr('class') == sel ? 1 : sel.indexOf(d3.select(this).attr('class')) > -1 ? 1 : 0
        })
        .attr('stroke', function(d){
          var hl = highlight[sel.indexOf(d3.select(this).attr('class'))]
          return hl == 0 ? '#bbb' : colorArr[hl]
        })
    }
    var updateBCSlide = (type, title) => {
      type = parseInt(type)
      $('#methodology-body .slide').removeClass('active')
      $('#methodology-body .slide[data-type="' + type + '"]').addClass('active')
      if(title !== null){
        $('svg text.chart-title').text(title)
      }
      if(type > 5 && type < 13){
        // in the line chart now
        bcSvg.transition().duration(1000)
          .style('opacity', 0)
        lineChart.transition().duration(1000)
          .style('opacity', 1)
        bc2Svg.transition().duration(1000)
          .style('opacity', 0)
        switch(type-5){
          case 1:
            d3.select('.first-y-axis').transition().duration(defaultAnimationTime)
              .style('opacity',1)
            d3.select('.second-y-axis').transition().duration(defaultAnimationTime)
              .style('opacity',0)
            showLine({
              show: ['net_cost_short'],
              highlights: [1]
            })
            break
          case 2:
            d3.select('.first-y-axis').transition().duration(defaultAnimationTime)
              .style('opacity',.25)
            d3.select('.second-y-axis').transition().duration(defaultAnimationTime)
              .style('opacity',1)
            showLine({
              show: ['net_cost_short', 'mean_acs_wage'],
              highlights: [0, 1]
            })
            break
          case 3:
            showLine({
              show: ['net_cost_short', 'mean_acs_wage', 'control_mean_wage_short'],
              highlights: [0, 0, 1]
            })
            break
          case 4:
            showLine({
              show: ['net_cost_short', 'mean_acs_wage','control_mean_wage_short','control_mean_wage_long'],
              highlights: [0, 0, 1, 5]
            })
            break
          case 5:
            showLine({
              show: ['net_cost_short', 'mean_acs_wage','control_mean_wage_short','control_mean_wage_long', 'treatment_wage_obs'],
              highlights: [0, 0, 0, 0, 1]
            })
            break
          case 6:
            d3.select('.first-y-axis').transition().duration(defaultAnimationTime)
              .style('opacity',.25)
            d3.select('.second-y-axis').transition().duration(defaultAnimationTime)
              .style('opacity',1)
            showLine({
              show: ['net_cost_short', 'mean_acs_wage','control_mean_wage_short','control_mean_wage_long', 'treatment_wage_obs', 'treatment_wage_proj'],
              highlights: [0, 0, 0, 0, 1, 5]
            })
            break
          case 7:
            d3.select('.first-y-axis').transition().duration(defaultAnimationTime)
              .style('opacity',1)
            d3.select('.second-y-axis').transition().duration(defaultAnimationTime)
              .style('opacity',0)
            showLine({
              show: ['net_cost_short', 'l_net_cost_short', 'u_net_cost_short', 'net_cost', 'l_net_cost', 'u_net_cost'],
              highlights: [1, 0, 0, 1, 0, 0]
            })
            break
        }
      } else if(type < 13) {
        // In the bar chart
        bcSvg.transition().duration(1000)
          .style('opacity', 1)
        lineChart.transition().duration(1000)
          .style('opacity', 0)
        bc2Svg.transition().duration(1000)
          .style('opacity', 0)
        // bcSvg.selectAll('.bc-g').transition().duration(1000)
        //   .attr('transform', (d,i) => {
        //     var y = bcY(d.runningTotal-d.val)//d.val < 0 ? bcY(d.runningTotal) : bcY(d.runningTotal-d.val)
        //     return i <= parseInt(type) ? `translate(${bcX(i)}, ${bcY(d.runningTotal)})` : `translate(${bcX(i)}, ${y})`
        //   })
        bcSvg.selectAll('.bc-rect').transition().duration(1000)
          .attr('height', (d,i) => {
            return i <= type ? bcH(Math.abs(d.val)) : 0 })
            .attr('transform', (d,i) => i <= type ? 'translate(0, 0)' : d.val < 0 ? 'translate(0, 0)' : `translate(0, ${bcH(d.val)})`)
        bcSvg.selectAll('.label-num.bc-text').transition().duration(1000)
          .style('opacity', (d,i) => {
            return i <= type ? 1 : 0 })
        bcSvg.selectAll('.label-name-group').transition().duration(1000)
          .style('opacity', (d,i) => {
            return i <= type ? 1 : 0 })
      } else {
        type = type - 13
        // second bar chart options
        bcSvg.transition().duration(1000)
          .style('opacity', 0)
        lineChart.transition().duration(1000)
          .style('opacity', 0)
        bc2Svg.transition().duration(1000)
          .style('opacity', 1)
        // bc2Svg.selectAll('.bc-g').transition().duration(1000)
        //   .attr('transform', (d,i) => {
        //     var y = bcY2(d.runningTotal-d.val)//  d.val < 0 ? bcY2(d.runningTotal) : bcY2(d.runningTotal-d.val)
        //     return i <= type ? `translate(${bcX2(i)}, ${bcY2(d.runningTotal)})` : `translate(${bcX2(i)}, ${y})`
        //   })
        bc2Svg.selectAll('.bc-rect').transition().duration(1000)
          .attr('height', (d,i) => {
            return i <= type ? bcH2(Math.abs(d.val)) : 0 })
          .attr('transform', (d,i) => i <= type ? 'translate(0, 0)' : d.val < 0 ? 'translate(0, 0)' : `translate(0, ${bcH2(d.val)})`)
        bc2Svg.selectAll('.label-num.bc-text').transition().duration(1000)
          .style('opacity', (d,i) => {
            return i <= type ? 1 : 0 })
        bc2Svg.selectAll('.label-name-group').transition().duration(1000)
          .style('opacity', (d,i) => {
            return i <= type ? 1 : 0 })

      }

      makeByChartByData(bcSvg, bcDataRaw, bcX, bcY, bcH)
      makeByChartByData(bc2Svg, bc2DataRaw, bcX2, bcY2, bcH2)

    }

    $('#methodology-body').scroll(function(){
      if($(this).scrollTop() > 110){
        if(!$('.methodology-body').hasClass('stuck')) $('.methodology-body').addClass('stuck')
      } else {
        $('.methodology-body').removeClass('stuck')
      }
    })
    var waypointOffset = window.innerWidth < 600 ? 80 : 50
    var waypointOffsetUp = window.innerWidth < 600 ? 20 : 0

    $('.bc-slide').each(function(){
      var id = 'id'+Math.round(Math.random()*1000000)
      $(this).attr('id', id)
      var type = $(this).data('type')
      var title = $(this).data('title')
      var waypoint = new Waypoint({
        element: document.getElementById(id),
        handler: (direction) => {
          if(direction === 'down'){
            updateBCSlide(type, title)
          }
        },
        offset: waypointOffset + '%',
        context: document.getElementById('methodology-body')
      })
      var waypoint = new Waypoint({
        element: document.getElementById(id),
        handler: (direction) => {
          if(direction === 'up'){
            updateBCSlide(type, title)
          }
        },
        offset: waypointOffsetUp + '%',
        context: document.getElementById('methodology-body')
      })
    })

    var chartTitle = methSvg.append('text')
      .text('Net Cost to Government')
      .attr('class', 'chart-title')
      .attr('x', 0)
      .attr('y', 15)


    var lineChart = methSvg.append('g')
      .attr('transform', `translate(${bcMargin[3]}, ${bcMargin[0]})`)

    function makeLineChart(data){
      var bcSelector = '.methodology-viz'
      var selectorWidth, selectorHeight
      d3.select(bcSelector).each(function(){
        bcSelectorWidth = this.getBoundingClientRect().width
      })
      let bcWidth = bcSelectorWidth - bcMargin[1] - bcMargin[3];
      let bcHeight = $('#methodology-scroll').outerHeight() - 25 - bcMargin[0] - bcMargin[2]
      $('.methodology-header').outerHeight()
          bcHeight = window.innerWidth < 600 ? $('#methodology-scroll').outerHeight()/2 - $('.methodology-header').outerHeight() - bcMargin[0] - bcMargin[2] : bcHeight
      var lineMargin = window.innerHeight < 600 ? 0 : 40
      var x = d3.scaleLinear().domain([0, 65]).range([0, bcWidth])
      var y = d3.scaleLinear().domain([-58748.332, 58748.332]).range([bcHeight, 0])
      var secondY = d3.scaleLinear().domain([0, 60000]).range([bcHeight, 0])
      var lineXSel = 'age'
      var lineYSel = 'net_cost'
      var lineGenerator = d3.line()
        .x(d => x(d.values[0][lineXSel]))
        .y(d => y(d.values[0][lineYSel]))
      var secondLineGenerator = d3.line()
        .x(d => x(d.values[0][lineXSel]))
        .y(d => secondY(d.values[0][lineYSel]))

      var lineGroup = lineChart.selectAll('path')
        .data([data]).enter()

      var yAxis = d3.axisLeft(y)

      var secondYAxis = d3.axisRight(secondY)

      var xAxis = d3.axisBottom(x)

      var yAxisGroup = lineChart
        .append('g')
          .attr('class', 'y-axis first-y-axis axis')
          .call(yAxis)
          .attr('transform', `translate(${lineMargin}, 0)`)
          .style('opacity', 0)

      var secondYAxisGroup = lineChart
        .append('g')
          .attr('class', 'y-axis second-y-axis axis')
          .call(secondYAxis)
          .attr('transform', `translate(${bcWidth-lineMargin}, 0)`)
          .style('opacity', 0)

      var xAxisGroup = lineChart
        .append('g')
          .attr('class', 'y-axis axis')
          .call(xAxis)
          .attr('transform', `translate(${0}, ${bcHeight/2})`)
          .style('opacity', 0)

      yAxisGroup.selectAll('text')
        .text((d,i) => {
          return d == 50000 ? '$' + d/1000 + 'K' : d/1000
        })

      secondYAxisGroup.selectAll('text')
        .text((d,i) => {
          return d == 60000 ? '$' + d/1000 + 'K' : d/1000
        })

      var midAxis = lineChart.append('line')
        .attr('x1', lineMargin)
        .attr('x2', width-lineMargin)
        .attr('y1', y(0))
        .attr('y2', y(0))
        .attr('stroke', '#bbb')
        .attr('stroke-width', 1)

      function addLine(sel, linegen){
        lineYSel = sel

        lineGroup.append('path')
          .attr('d', d => {
            return linegen(d.filter(d => d.values[0][lineYSel] !== null))
          })
          .attr('fill', 'transparent')
          .attr('stroke', mainColor)
          .attr('stroke-width', 2)
          .style('opacity', 0)
          .attr('class', sel)
      }

      addLine('net_cost', lineGenerator)
      addLine('l_net_cost', lineGenerator)
      addLine('u_net_cost', lineGenerator)
      addLine('net_cost_short', lineGenerator)
      addLine('l_net_cost_short', lineGenerator)
      addLine('u_net_cost_short', lineGenerator)
      addLine('mean_acs_wage', secondLineGenerator)
      addLine('control_mean_wage_short', secondLineGenerator)
      addLine('control_mean_wage_long', secondLineGenerator)
      addLine('treatment_wage_proj', secondLineGenerator)
      addLine('treatment_wage_obs', secondLineGenerator)

    }

    processedLineData = lineData.map(d => {
      d.net_cost_short = d.age <= 33 ? d.net_cost : null
      d.l_net_cost_short = d.age <= 33 ? d.l_net_cost : null
      d.u_net_cost_short = d.age <= 33 ? d.u_net_cost : null
      d.control_mean_wage_long = d.age >= 33 ? d.control_mean_wage : null
      d.control_mean_wage_short = d.age <= 33 ? d.control_mean_wage : null
      return d
    })

    // Make line chart using this svg
    var nestedLineData = d3.nest()
      .key(d => d.age) // this is what we'll group by
      .entries(processedLineData) // based on our data

    makeLineChart(nestedLineData)

  } // end make bar Chart

  // Process barchart data
  function processBCData(bcData){
    for(var i = 0; i < bcData.length; i++){
      d = bcData[i]
      d.labelArr = d.item.split('<br />')
    }
    return bcData
  }

  bcDataProcessed = processBCData(bcData)
  bc2DataProcessed = processBCData(bc2Data)

  makeBarChart(bcDataProcessed, bc2DataProcessed)
  function premakeBarChart(){
    makeBarChart(bcDataProcessed, bc2DataProcessed)
  }

})() // end of trigger function
